/* Default style for Nav.Link */
.nav-link-custom {
    color: white; /* Default text color */
    background-color: transparent; /* Explicit default background color */
    transition: background-color 2.5s ease-in-out; /* Transition for background color */
}

/* Hover style for Nav.Link */
.nav-link-custom:hover {
    background-color: black; /* Background color on hover */
    /* The transition property is already defined in the default state */
}

