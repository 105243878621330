/* App.css */
.grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns */
    grid-template-rows: auto auto; /* Two rows, auto-sized */
    gap: 10px; /* Space between images */
  }
  
  .grid-image {
    width: 100%; /* Make images take full width of the cell */
    height: auto; /* Keep aspect ratio */
  }
  